import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslocoModule} from '@ngneat/transloco';

import {TrialWarningModalComponent} from './components/trial-warning-modal/trial-warning-modal.component';
import {TrialWarningDirective} from './directives/trial-warning.directive';
import {TrialWarningService} from './services/trial-warning.service';

import {ModalModule} from '../modal';
import {SeazoneIconRegistryService, SeazoneIconsModule, warningTriangleIcon} from '../seazone-icons';

@NgModule({
  declarations: [TrialWarningModalComponent, TrialWarningDirective],
  imports: [
    CommonModule,
    ModalModule,
    TranslocoModule,
    SeazoneIconsModule,
  ],
  providers: [TrialWarningService],
  exports: [TrialWarningDirective],
})
export class TrialWarningModule {
  constructor(
    private readonly seazoneIconRegistryService: SeazoneIconRegistryService,
  ) {
    this.seazoneIconRegistryService.registerIcons([warningTriangleIcon]);
  }
}
