import {Directive, Input, OnInit} from '@angular/core';
import {take, takeUntil} from 'rxjs/operators';

import {AppRouterService} from '@core/services';
import {DestroySubscription} from '@helpers/destroy-subscription';

import {TrialWarningService} from '../services/trial-warning.service';

@Directive({
  selector: '[appTrialWarning]',
})
export class TrialWarningDirective extends DestroySubscription implements OnInit {

  @Input() appTrialWarning: string;

  constructor(
    private readonly trialWarningService: TrialWarningService,
    private readonly appRouterService: AppRouterService,
  ) {
    super();
  }

  ngOnInit() {
    this.trialWarningService.open(this.appTrialWarning || 'subscription.trialWarning').pipe(
      take(1),
      takeUntil(this.destroyStream$),
    ).subscribe(data => {
      if (!data) {
        this.appRouterService.goBack();
      }
    });
  }

}
